<template>
  <div id="contact">
    <div class="black-box-container">
      <Logo />
      <Sidebar />
      <v-container class="py-5">
        <v-layout class="pt-10" row wrap align-center justify-center>
          <v-flex xs12>
            <div class="contact-text mt-2">
              <span class="main-text">Contact me</span>
              <span class="sub-text">
                Feel free to
                <a
                  href="mailto:itayblayer@gmail.com?Subject=SiteContact"
                  class="contact-form-style"
                  target="_top"
                  >itayblayer@gmail.com</a
                >
                or fill the form below
              </span>
            </div>
          </v-flex>
        </v-layout>
        <v-layout
          class="pb-5"
          row
          wrap
          align-center
          justify-center
          text-xs-center
        >
          <v-flex xl6 lg6 md6 sm12 xs11>
            <form
              action="https://formspree.io/itayblayer@gmail.com"
              class="contact-form mt-4"
              method="POST"
            >
              <input type="text" name="name" required />
              <label placeholder="Your name"></label>
              <input type="Email" name="_replyto" required />
              <label placeholder="Your email"></label>
              <textarea name="message" required></textarea>
              <label placeholder="Your Message"></label>
              <v-btn
                large
                type="submit"
                value="Send"
                class="text-none"
                block
                round
                color="primary"
                dark
                >Send it away</v-btn
              >
            </form>
          </v-flex>
          <!-- <v-flex xl5 lg6 md6 sm12 xs11>
            <div class="contact-map mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.7115667294015!2d34.791194950061914!3d32.077049181096605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4b9996ef9871%3A0xa8063be628c45632!2sWeWork+Derech+Menachem+Begin+144!5e0!3m2!1sen!2sca!4v1565629326737!5m2!1sen!2sca"
                width="100%"
                height="382"
                frameborder="0"
                style="border:0"
                allowfullscreen
              ></iframe>
              <v-btn
                href="https://www.google.com/maps/place/WeWork+Derech+Menachem+Begin+144/@32.0770492,34.791195,17z/data=!3m1!4b1!4m5!3m4!1s0x151d4b9996ef9871:0xa8063be628c45632!8m2!3d32.0770492!4d34.793389"
                target="blank"
                large
                outline
                class="text-none hidden-sm-and-up"
                block
                round
                color="primary"
                dark
                >Navigate</v-btn
              >
            </div>
          </v-flex> -->
        </v-layout>
      </v-container>
      <v-footer class="text-xs-center mb-2" absolute color="transparent">
        <div class="copyright-text-contact" style="width: 100%">
          All rights reserved Itay Blayer
        </div>
      </v-footer>
    </div>
  </div>
</template>

<style scoped>
.black-box-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 5;
  overflow: hidden;
}

/* ------------------- */
/* --- typography ---- */
/* ------------------- */
.main-text {
  display: block;
  margin-left: 60px;
  margin-bottom: 10px;
  font-family: futura-pt;
  font-size: 65px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.sub-text {
  display: block;
  margin-left: 60px;
  margin-bottom: 50px;
  text-align: left;
  font-family: futura-pt;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.88;
  letter-spacing: -0.82px;
  color: #959595;
}
.contact-form-style {
  color: #3369e2;
  border-bottom: 2px solid #3369e2;
}
.copyright-text-contact {
  width: 166px;
  height: 17px;
  font-family: futura-pt;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.31px;
  text-align: center;
  color: #535353;
}

/* ------------------ */
/* --- Spacing ---- */
/* ------------------- */
.pb-10 {
  padding-bottom: 100px;
}
.pt-10 {
  margin-top: 100px;
}
/* ------------------- */
/* ---------Map------ */
/* ------------------- */
iframe {
  max-width: 738px;
  max-height: 382px;
  border-radius: 4px;
}
.contact-map {
  margin: 0 auto;
  text-align: center;
  position: relative;
}
/* ------------------- */
/* ---------Form------ */
/* ------------------- */
.contact-form {
  max-width: 440px;
  text-align: left;
  margin: 0 auto;
}
#contact {
  background-color: #0b0b0b;
  height: 100%;
}
input,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 2 */
  margin: 0;
  /* 3 */
  /* box-sizing: border-box; */
}
label {
  box-sizing: border-box;
}
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}
input[type="email"],
input[type="text"] {
  width: 100%;
  color: #fff;
  background-color: #111111;
  font-family: futura-pt;
  height: calc(3em + 2px);
  padding: 1em;
  font-size: 1em;
  border: 1px solid #757575;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  box-shadow: none;
  resize: none;
  outline: none;
}
input[type="email"]:hover,
input[type="text"]:hover {
  border: 1px solid #757575;
}
input[type="email"] + label,
input[type="text"] + label {
  font-family: futura-pt;
  display: block;
  font-size: 1em;
  height: 3em;
  text-align: left;
  line-height: 3em;
  margin-top: calc(-2.8em - 1px);
  margin-bottom: 1em;
  pointer-events: none;
}
input[type="email"] + label:before,
input[type="text"] + label:before {
  content: attr(placeholder);
  display: inline-block;
  font-family: futura-pt;
  color: #999999;
  margin: 0 calc(1em + 2px);
  white-space: nowrap;
  transition-property: color, -webkit-transform;
  transition-property: transform, color;
  transition-property: transform, color, -webkit-transform;
  transition-duration: 0.2s;
  transition-delay: 0;
  transition-timing-function: ease-out;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}
input[type="email"]:focus + label:before,
input[type="email"].active + label:before,
input[type="email"]:valid + label:before,
input[type="text"]:focus + label:before,
input[type="text"].active + label:before,
input[type="text"]:valid + label:before {
  background: #0b0b0b;
  line-height: 1;
  color: #535353;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 16px;
  -webkit-transform: translateY(calc(-3em / 2 - 1px / 2)) scale(0.8, 0.8);
  transform: translateY(calc(-3em / 2 - 1px / 2)) scale(0.8, 0.8);
}
textarea {
  width: 100%;
  font-family: futura-pt;
  background-color: #111111;
  height: calc(15em + 2px);
  padding: 1em;
  font-size: 1em;
  border: 1px solid #757575;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  box-shadow: none;
  resize: none;
  outline: none;
  color: #fff;
}
textarea + label {
  font-family: futura-pt;
  display: block;
  font-size: 1em;
  height: calc(15em + 2px);
  line-height: 1;
  padding-top: calc(1em + 1px);
  margin-top: calc(-15em - 2px);
  text-align: left;
  margin-bottom: 1em;
  pointer-events: none;
}
textarea + label:before {
  content: attr(placeholder);
  display: inline-block;
  color: #999999;
  margin: 0 calc(1em + 2px);
  white-space: nowrap;
  transition-property: color, -webkit-transform;
  transition-property: transform, color;
  transition-property: transform, color, -webkit-transform;
  transition-duration: 0.2s;
  transition-delay: 0;
  transition-timing-function: ease-out;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}
textarea:focus + label:before,
textarea.active + label:before,
textarea:valid + label:before {
  background: #0b0b0b;
  line-height: 1;
  color: #535353;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 16px;
  -webkit-transform: translateY(calc((-1em - 0.5em) - 0.5px)) scale(0.8, 0.8);
  transform: translateY(calc((-1em - 0.5em) - 0.5px)) scale(0.8, 0.8);
}
/* ------------------- */
/* ---- Responsive --- */
/* ------------------- */

@media (min-width: 960px) and (max-width: 1263px) {
  .contact-map {
    padding: 20px !important;
  }
}
@media (max-width: 960px) {
  iframe {
    max-width: 440px;
    max-height: 375px;
    margin-top: 30px;
  }
  .main-text {
    margin-left: 0px;
    font-size: 46px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .sub-text {
    margin-left: 0px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    margin-bottom: 20px;
    font-style: italic;
    line-height: 1.46;
    letter-spacing: -0.58px;
    text-align: center;
    color: #959595;
  }
}
/* @media (max-width: 600px) {
  .main-text {
  font-family: futura-pt;
  font-size: 46px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  }
  .sub-text {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.67px;
  text-align: center;
  color: #959595;
  }
  .contact-map {
    max-width: 440px;
  }
  .sub-text {
    max-width: 400px;
  }
} */
@media (min-width: 1904px) {
  .contact-map {
    margin-left: -200px;
  }
  .main-text {
    margin-left: 260px;
  }
  .sub-text {
    margin-left: 260px;
  }
}
</style>

<script>
import Logo from "@components/_logo.vue";
import Sidebar from "@components/_sidebarNav.vue";

export default {
  components: {
    Logo,
    Sidebar
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }
};
</script>
